/**
 * Created by TapFun on 2022/12/21.
 */
import qs from 'qs';
import {SERVER_HOST} from '../common/portConfig';
import request from '@/common/request'

export const getProjectByCode = params => {
    return request.get(`/client/home/getProjectByCode`, {
        params: params
    })
};

export const getPositionByProjectCode = params => {
    return request.get(`/client/home/getPositionByProjectCode`, {
        params: params
    })
};

export const getPositionByCode = params => {
    return request.get(`/client/home/getPositionByCode`, {
        params: params
    })
};

export const reqClientLogin = params => {
    return request.post(`/client/login`, qs.stringify(params)).then(res => res.data)
};

// 通知列表分页查询
export const reqMessagePage = params =>{
    return request.get(`/client/message/page`, {
        params: params
    })
};
// 通知列表查询
export const reqMessageCenter = params =>{
    return request.get(`/client/message/center`, {
        params: params
    })
};
export const getCountOfUnreadMessage = params => {
    return request.get(`/client/message/countOfUnread`, {params: params})
};

export const reqEditStatus = params =>{
    return request.post(`/client/message/editStatus`,params)
}

// 我的报名列表分页查询
export const reqApplicationPage = params =>{
    return request.get(`/client/application/page`, {
        params: params
    })
};
// 我的报名列表查询
export const reqApplicationCenter = params =>{
    return request.get(`/client/application/center`, {
        params: params
    })
};

// 修改密码
export const updateApplicantPwd = params =>{
    return request.post(`/client/applicant/updatePwd`,params)
}
// 上传资审材料
export const editStatus = params =>{
    return request.post(`/client/submit/editStatus`,params)
}
// 发送验证码
export const reqSendAuthCode = params => {
    return request.get(`/client/applicant/authCode/` + params.phone, {params: params})
};
// 客户端用户注册
export const reqRegisterApplicant = params =>{
    return request.post(`/client/applicant/register`,params)
}
// 客户端用户忘记密码
export const reqForGetPWDApplicant = params =>{
    return request.post(`/client/applicant/forGetPWD`,params)
}
// 通过id获取简历信息
export const reqGetImgUrlBySourceId = params => {
    return request.get(`/positionApplicant/getImgById`, {
        params: params
    })
};
export const getPositionById = params => {
    return request.get(`/client/submit/getPositionById`, {
        params: params
    })
};
export const getApplicantById = params=>{
    return request.get('/client/submit/getApplicantById',{
        params: params
    })
}
// 上传签名
export const reqUploadSignature = params => {
    return request.post(`/uploadFile`,
        params,
        {"Content-Type": "multipart/form-data", Authorization: sessionStorage.getItem("Authorization")}
    )
};
// 报名申请
export const reqApplyPosition = params =>{
    return request.post(`/client/application/position`, params)
}
// 通过手机号获取投递数量
export const getSendNumberByPhone = params=>{
    return request.get('/client/home/getSendNumberByPhone',{
        params: params
    })
}
// 查询当前登录用户对项目、职位可用的投递简历次数
export const reqGetAvailsPerApplicant = params=>{
    return request.get('/client/application/avails',{
        params: params
    })
}
// 查询当前登录用户
export const getClientUser = params=>{
    return request.get('/client/applicant/getClientUser',{
        params: params
    })
}
// 校验手机号码是否已使用
export const phoneIsUsed = params=>{
    return request.get('/client/applicant/phoneIsUsed',{
        params: params
    })
}
