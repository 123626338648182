import axios from 'axios'
import { SERVER_HOST } from './portConfig';
import {_isMobile} from '../utils/utils'
import { Modal } from 'view-design';
import { Dialog } from 'vant';

// create an axios instance
const service = axios.create({
    baseURL: SERVER_HOST,
    // timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        config.headers.Authorization = sessionStorage.getItem('Authorization');
        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        if (response.data.code === 409) {
            let redirectTo = window.location.href;
            if (_isMobile()) {
                Dialog.alert({
                    title: '温馨提示',
                    message: '登录状态失效，请重新登录',
                }).then(() => {
                    localStorage.removeItem("Authorization");
                    localStorage.setItem('redirectTo', redirectTo);
                    window.location.href = '/login';
                });
            } else {
                Modal.confirm({
                    title: "温馨提示",
                    content: "登录状态失效，请重新登录",
                    width: '240',
                    onOk: () => {
                        sessionStorage.removeItem("Authorization");
                        sessionStorage.setItem('redirectTo', redirectTo);
                        window.location.href = '/login';
                    }
                });
            }
            return Promise.reject(new Error(response.data.message || 'Error'))
        } else {
            return response;
        }
    }, error => {
        return Promise.reject(error);
    }
)

export default service
